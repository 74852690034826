export const initTipsSelector = () => {
	const tips = document.querySelectorAll('#tips_selectors button');
	document.querySelector('#tips_selectors').addEventListener('click', e => {
		const clickedTip = e.target.closest('button');

		if(clickedTip) {
			tips.forEach(tip => tip.removeAttribute('active'));
			clickedTip.setAttribute('active', true);

			document.querySelector('#tips_box h4').innerHTML = clickedTip.getAttribute('title').toUpperCase();
			document.querySelector('#tips_box p').innerHTML = clickedTip.getAttribute('text');
		}
	});

	document.querySelector('#tips_selectors button').click();
};